@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/3b47b83197930677-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/0b06635008c26543-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/9fa91490ac589088-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: italic;
}@font-face {font-family: '__rijksFont_Fallback_99240a';src: local("Arial");ascent-override: 97.57%;descent-override: 30.28%;line-gap-override: 0.00%;size-adjust: 92.14%
}.__className_99240a {font-family: '__rijksFont_99240a', '__rijksFont_Fallback_99240a', Tahoma, sans-serif
}

@layer sections;
@layer sections {
  .arm2di0 {
    display: grid;
    grid-template-columns: [logo-start] minmax(0, 1fr) [logo-end rijks-start] auto [rijks-end] minmax(0, 1fr);
    grid-template-rows: [rijks-start] 0 [logo-start] auto [logo-end rijks-end] 0;
    grid-gap: calc(2.488 * var(--_2s5idz1));
    gap: calc(2.488 * var(--_2s5idz1));
    grid-column: page;
  }
  .arm2di1 {
    grid-area: logo;
    justify-self: start;
    align-self: center;
  }
  .arm2di2 {
    justify-self: center;
    align-self: start;
    grid-area: rijks;
  }
  .arm2di3 {
    display: block;
    inline-size: calc(3.583 * var(--_2s5idz1));
  }
  .arm2di4 {
    position: relative;
    isolation: isolate;
  }
  .arm2di4::after {
    content: "";
    position: absolute;
    inset-inline: 0;
    inset-block-end: 0;
    background-color: #d5e9f6;
    block-size: 4px;
    z-index: -1;
  }
  .arm2di5 {
    position: relative;
    isolation: isolate;
  }
  .arm2di5::after {
    content: "";
    position: absolute;
    inset-inline: 0;
    inset-block-end: 0;
    background-color: #d5e9f6;
    block-size: 4px;
    z-index: -1;
  }
  @media (max-width: 639.98px) {
    .arm2di0 {
      gap: calc(1.200 * var(--_2s5idz1));
      grid-template-columns: [logo-start rijks-start] minmax(0, 1fr) [logo-end rijks-end];
      grid-template-rows: [rijks-start] auto [rijks-end logo-start] auto [logo-end] 0;
    }
    .arm2di1 {
      justify-self: center;
      align-self: start;
    }
    .arm2di3 {
      inline-size: calc(2.986 * var(--_2s5idz1));
      margin-block-start: calc(-1 * calc(1.728 * var(--_2s5idz1)));
    }
  }
}
@layer sections;
@layer sections {
  ._1veknhd0 {
    display: block;
    inline-size: auto;
    block-size: calc(2.950 * var(--_2s5idz1));
    overflow: visible;
  }
  ._1veknhd1 {
    display: block;
    block-size: calc(3.540 * var(--_2s5idz1));
    overflow: visible;
  }
  @media (max-width: 639.98px) {
    ._1veknhd0 {
      block-size: calc(2.458 * var(--_2s5idz1));
    }
  }
}
